// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dots-1 {
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #000 90%, #0000) 0 /
    calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: d1 1s steps(4) infinite;
}
@keyframes d1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf;6BAC2B;EAC3B,4BAA4B;EAC5B,kCAAkC;AACpC;AACA;EACE;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".dots-1 {\n  width: 60px;\n  aspect-ratio: 4;\n  background: radial-gradient(circle closest-side, #000 90%, #0000) 0 /\n    calc(100% / 3) 100% space;\n  clip-path: inset(0 100% 0 0);\n  animation: d1 1s steps(4) infinite;\n}\n@keyframes d1 {\n  to {\n    clip-path: inset(0 -34% 0 0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
