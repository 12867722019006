// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.sub-container {
  width: 80%;
  height: 80%;
  border-radius: 10px;
  box-shadow: -2px 2px 5px 4px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.slots-div {
  width: 200px;
}

.appointment-div {
  min-width: 350px;
  border-right: 1px solid rgb(193, 193, 193);
  height: calc(100% + 40px);
  margin: -20px 0;
}

.sub-container:nth-last-of-type(3) {
  background-color: green;
}

.transparent-backdrop {
  position: fixed; /* Covers the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it's above other elements */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/mainPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,gDAAgD;EAChD,aAAa;EACb,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,0CAA0C;EAC1C,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,eAAe,EAAE,6BAA6B;EAC9C,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B,EAAE,2BAA2B;EAC3D,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa,EAAE,qCAAqC;AACtD","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.sub-container {\n  width: 80%;\n  height: 80%;\n  border-radius: 10px;\n  box-shadow: -2px 2px 5px 4px rgba(0, 0, 0, 0.05);\n  padding: 20px;\n  display: flex;\n  justify-content: space-between;\n}\n.slots-div {\n  width: 200px;\n}\n\n.appointment-div {\n  min-width: 350px;\n  border-right: 1px solid rgb(193, 193, 193);\n  height: calc(100% + 40px);\n  margin: -20px 0;\n}\n\n.sub-container:nth-last-of-type(3) {\n  background-color: green;\n}\n\n.transparent-backdrop {\n  position: fixed; /* Covers the entire screen */\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 9999; /* Ensure it's above other elements */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
