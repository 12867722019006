// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.apointment-lhs-container{
  padding: 10px;
  border-radius: 5px;
}
.appointment-main-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.booked-appointments-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.appointment-container {
  height: 500px;
  overflow-y: auto;
  padding: 10px;
  border-radius: 5px;
  scrollbar-width: none;
}
.appointment-name-div,
.appointment-time-div,
.appointment-duration-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
.appointment-text {
  padding: 7px;
}
.appointment-duration-div {
  margin: 0;
}
.appointment-name-div h3,
.appointment-time-div p {
  margin: 0;
  padding: 0;
}
.appointment-time-div,
.appointment-duration-div {
  color: rgb(110, 110, 107);
}
`, "",{"version":3,"sources":["webpack://./src/components/appointment.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,qBAAqB;AACvB;AACA;;;EAGE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,SAAS;EACT,UAAU;EACV,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,SAAS;AACX;AACA;;EAEE,SAAS;EACT,UAAU;AACZ;AACA;;EAEE,yBAAyB;AAC3B","sourcesContent":["\n.apointment-lhs-container{\n  padding: 10px;\n  border-radius: 5px;\n}\n.appointment-main-container {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  border-radius: 5px;\n  background-color: #f9f9f9;\n  margin-bottom: 10px;\n}\n\n.booked-appointments-container {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  background-color: #f9f9f9;\n  margin-bottom: 10px;\n}\n\n.appointment-container {\n  height: 500px;\n  overflow-y: auto;\n  padding: 10px;\n  border-radius: 5px;\n  scrollbar-width: none;\n}\n.appointment-name-div,\n.appointment-time-div,\n.appointment-duration-div {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 10px;\n  margin: 0;\n  padding: 0;\n  margin-bottom: 10px;\n}\n.appointment-text {\n  padding: 7px;\n}\n.appointment-duration-div {\n  margin: 0;\n}\n.appointment-name-div h3,\n.appointment-time-div p {\n  margin: 0;\n  padding: 0;\n}\n.appointment-time-div,\n.appointment-duration-div {\n  color: rgb(110, 110, 107);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
