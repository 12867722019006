// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slots-main-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  min-width: 200px;
  height: 500px;
  overflow-y: auto;
  padding: 10px;
  border-radius: 5px;
  scrollbar-width: thin;
  scrollbar-color: #eff4fe #eff4fe;
  margin-top: 50% !important;
}

.slot-container {
  display: flex;
  width: 100%;
  transition: all 0.4s ease-in-out;
  gap: 10px;
  margin-bottom: 10px;
}

.slot-button {
  flex: 1 1;
  background: transparent;
  border: 1px solid #155eee;
  color: #155eee;
  padding: 10px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  text-align: center;
}

.slot-button:hover {
  border-width: 2px;
}

.shrink {
  flex: 0.5 1;
}

.select-button {
  flex: 0.5 1;
  background-color: #155eee;
  color: #eff4fe;
  padding: 10px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.1s ease-in-out;
}

.expanded .select-button {
  opacity: 1;
  transform: translateX(0);
}

.selected {
  color: #fff;
  background-color: #6c6868;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/availableSlots.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,gCAAgC;EAChC,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,WAAW;EACX,gCAAgC;EAChC,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,uBAAuB;EACvB,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAS;AACX;;AAEA;EACE,WAAS;EACT,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,UAAU;EACV,4BAA4B;EAC5B,gCAAgC;AAClC;;AAEA;EACE,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".slots-main-container {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  width: 100%;\n  min-width: 200px;\n  height: 500px;\n  overflow-y: auto;\n  padding: 10px;\n  border-radius: 5px;\n  scrollbar-width: thin;\n  scrollbar-color: #eff4fe #eff4fe;\n  margin-top: 50% !important;\n}\n\n.slot-container {\n  display: flex;\n  width: 100%;\n  transition: all 0.4s ease-in-out;\n  gap: 10px;\n  margin-bottom: 10px;\n}\n\n.slot-button {\n  flex: 1;\n  background: transparent;\n  border: 1px solid #155eee;\n  color: #155eee;\n  padding: 10px;\n  font-size: 16px;\n  border-radius: 6px;\n  cursor: pointer;\n  transition: all 0.1s ease-in-out;\n  text-align: center;\n}\n\n.slot-button:hover {\n  border-width: 2px;\n}\n\n.shrink {\n  flex: 0.5;\n}\n\n.select-button {\n  flex: 0.5;\n  background-color: #155eee;\n  color: #eff4fe;\n  padding: 10px;\n  font-size: 16px;\n  border-radius: 6px;\n  cursor: pointer;\n  border: none;\n  opacity: 0;\n  transform: translateX(-10px);\n  transition: all 0.1s ease-in-out;\n}\n\n.expanded .select-button {\n  opacity: 1;\n  transform: translateX(0);\n}\n\n.selected {\n  color: #fff;\n  background-color: #6c6868;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
